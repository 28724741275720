@font-face {
  font-family: LemonMilk;
  src: url("./fonts/LemonMilk/Milk_light_300.otf");
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: LemonMilk;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(0, 0, 0, 0.9);
  .row {
    margin: 0;
  }
  a {
    cursor: pointer;
  }
}

.scroll-lock {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
@keyframes blink {
  50% {
    opacity: 0.4;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0.2;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
}
.home:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)),
    url(images/backgrounds/ica-compressed.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.community:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),
    url(images/backgrounds/community_bgr_compress.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.custom-cursor {
    cursor: url("/images/backgrounds/ica-kugis-small.png"), auto;
}
