.mail-chimp {
  position: fixed !important;
  transition: 0.5s ease all;
  transform: translate3d(150%, 0px, 0px);
  right: 5rem;
  top: 6.8rem;
  padding: 1.5rem 2rem 1rem 2rem;
  border-radius: 0.8rem;
  background-color: rgba($color: #000000, $alpha: 0.4);
  form {
    text-align: center;
    input {
      width: 100%;
      background-color: inherit;
      border: none;
      color: rgba(255, 255, 255, 0.9) !important;
      letter-spacing: 1.5px;
      &:focus {
        border: none;
        outline: none;
      }
    }
    input::placeholder {
      letter-spacing: 1px;
      color: rgba(255, 255, 255, 0.8) !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    button {
      margin-top: 0.7rem;
      border: none;
      background-color: rgba(255, 208, 70, 0.85);
      border-radius: 0.5rem;
      padding: 0.2rem 0.7rem;
      font-size: 14px;
      letter-spacing: 1px;
      color: rgba(66, 57, 62, 0.9) !important;
      transition: 0.2s ease all;
      &:hover {
        color: rgba(66, 57, 62, 1) !important;
        background-color: rgba(255, 208, 70, 1);
      }
    }
  }
  .msg-alert {
    p {
      margin-bottom: 0;
      margin-top: 1rem;
      font-size: 12px;
      color: rgba(236, 213, 143, 0.95) !important;
    }
  }
}
.mail-chimp.mobile {
  background-color: rgba(0, 0, 0, 0.95);
}
.mail-chimp.active,
.mail-chimp.mobile.active {
  transform: translate3d(0%, 0px, 0px);
}
@media only screen and (max-width: 992px) {
  .mail-chimp {
    right: 25%;
    left: 25%;
  }
}
@media only screen and (max-width: 576px) {
  .mail-chimp {
    right: 10%;
    left: 10%;
    input::placeholder {
      font-size: 14px;
    }
  }
}
