#gigs {
  color: rgba($color: #ffff, $alpha: 0.8);
  text-transform: capitalize !important;
  a,
  .bit-nav-bar,
  .bit-no-dates-title {
    letter-spacing: 2px !important;
  }
  .bit-widget {
    .bit-no-dates-container .bit-track-button {
      max-width: 284px !important;
      width: 100% !important;
      padding: 0.7rem 1rem;
      line-height: inherit;
    }
    .bit-offers {
      transition: 0.2s ease all;
      &:hover,
      &:active {
        color: rgba($color: #ffff, $alpha: 1);
        background-color: rgba($color: #000000, $alpha: 0.5);
        border: 1px solid rgba($color: #000000, $alpha: 0);
      }
    }
    .bit-top-track-button {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    .bit-venue,
    .bit-lineUp {
      color: rgba($color: #ffff, $alpha: 0.6);
    }
    .bit-rsvp,
    .bit-upcoming-events-show-all-button {
      transition: 0.2s ease all;
      color: rgba($color: #ffff, $alpha: 0.7);
      border: 1px solid rgba($color: #ffff, $alpha: 0.6);
      letter-spacing: 2px;
    }
    .bit-rsvp {
      transition: 0.2s ease all;
      &:hover,
      &:active {
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        border: 1px solid rgba($color: #ffffff, $alpha: 0);
      }
    }
    .bit-upcoming-events-show-all-button {
      margin-top: 3rem;
      margin-bottom: 3rem;
      &:hover {
        color: rgba($color: #ffff, $alpha: 0.9);
        border: 1px solid rgba($color: #000000, $alpha: 0);
        background-color: rgba($color: #000000, $alpha: 0.3);
      }
    }
    svg {
      max-width: 144px !important;
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 375px) {
  .bit-widget {
    min-width: 150px !important;
    .bit-top-track-button,
    .bit-event-list-title,
    .bit-no-dates-title,
    .bit-track-button {
      font-size: 14px !important;
    }
  }
}
