.header {
  .mobile-menu {
    width: 100% !important;
    .bm-menu {
      background: linear-gradient(
        rgba(0, 0, 0, 0.95) 20%,
        rgba(0, 0, 0, 0.7) 60%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
    ul {
      list-style: none;
      padding: 0;
      padding-top: 4rem;
      li {
        text-transform: uppercase;
        color: rgba($color: #ffffffff, $alpha: 0.8);
        padding-bottom: 0.5rem;
        letter-spacing: 0.5px;
        a {
          color: inherit;
          &:hover {
            text-decoration: underline;
          }
          &:active {
            color: rgba($color: #ffffffff, $alpha: 1);
          }
        }
      }
    }
    .mobile-newsletter {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: rgba(66, 57, 62, 0.7);
      }
    }
  }
}
.mail-chimp.mobile {
  border: 2px solid rgba(255, 208, 70, 0.55);
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 29px;
  height: 15px;
  right: 26px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: rgba(239, 240, 241, 0.9);
  transition: 0.2s ease all;
  position: relative;
  border-radius: 3px;
}

button {
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  opacity: 0.7;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 1.9rem !important;
  top: 1.3rem !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  opacity: 0.8;
  opacity: 0.8;
  height: 30px !important;
}

#react-burger-cross-btn {
  height: 40px !important;
  width: 40px !important;
  right: -15px !important;
  left: -2vw !important;
}
#react-burger-menu-btn {
  left: -1vh !important;
  top: -2.5vh !important;
  width: 50px !important;
  height: 50px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  left: 0;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background-color: transparent;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  // height: 100vh !important;
  // width: 100vw;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
  text-align: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin: 0% !important;
  display: inline-block !important;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}
