.recordings {
  .card:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 86%;
    z-index: 1;
    // background: rgba($color: #000000, $alpha: 0.3);
    left: -100%;
    transform-origin: center top 0;
    transition: 0.5s;
  }
  .card:hover:before {
    left: 0%;
  }
  .card {
    position: relative;
    overflow: hidden;
    background-color: inherit;
    border: none;
    border-radius: 0 !important;
    .card-img-top {
      border-radius: 0 !important;
    }
    .hover-text {
      text-align: center;
      width: 100%;
      position: absolute;
      top: -100%;
      z-index: 2;
      transition: 0.2s;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 0.5rem 0;
      hr {
        background-color: rgba(255, 255, 255, 0.5) !important;
        width: 50%;
        text-align: center;
        margin: 0.4rem auto;
      }
      a {
        color: rgba(255, 255, 255, 0.9) !important;
        transition: 0.2s ease all;
        &:hover {
          color: rgba(255, 255, 255, 0.7) !important;
        }
      }
      h3 {
        font-size: 22px;
        font-family: LemonMilk;
      }
    }
    &:hover {
      .hover-text {
        top: 26%;
      }
    }
    .card-body {
      color: rgba(255, 255, 255, 1) !important;
      padding: 0.3rem 0;
      text-align: center;
      .card-title {
        letter-spacing: 0.9px;
        margin-bottom: 0.25rem;
        font-size: 18px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .hover-text {
    top: 28% !important;
  }
}
@media only screen and (max-width: 992px) {
  .card {
    .hover-text {
      h3 {
        font-size: 18px !important;
      }
    }
    .card-body {
      svg {
        font-size: 18px;
        width: 25px !important;
      }
      .card-title {
        font-size: 14px !important;
      }
    }
  }
}
@media only screen and (max-width: 678px) {
  .card-text {
    svg {
      margin-right: 0.7rem;
    }
  }
  .hover-text {
    top: 27% !important;
  }
}
@media only screen and (max-width: 315px) {
  .hover-text {
    top: 22% !important;
  }
}
@media only screen and (max-width: 281px) {
  .hover-text {
    top: 16% !important;
  }
}
