.button-down {
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 8vh;
  //   left: 48vw;
  animation: blink 2s linear infinite;
  opacity: 1;
  svg {
    width: 20px;
    transform: rotate(90deg);
    opacity: 0.8;
    display: flex;
    justify-content: center;
    transition: 0.3s ease all;
  }
  &:hover {
    svg {
      opacity: 1;
      transform: rotate(90deg) translateX(0.5rem);
    }
  }
}
.button-down.hidden {
  display: none;
}
@media only screen and (max-width: 678px) {
  .button-down {
    // left: 46vw;
    bottom: 7vh !important;
  }
}
@media only screen and (max-width: 460px) {
  .button-down {
    // left: 45vw;
  }
}
@media only screen and (max-width: 375px) {
  .button-down {
    // left: 44vw;
    bottom: 9vh !important;
  }
}
