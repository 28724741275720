.recordings {
  .list-content {
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 1px;
    .stream {
      p {
        padding-right: 0.5rem;
      }
      svg {
        width: 30px;
      }
    }
    p {
      font-size: 1.2rem;
    }
    .lyrics {
      p {
        margin: 0;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .list-content {
    h3 {
      font-size: 1.5rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
}
