.alice-carousel__next-btn {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  padding: 0 !important;
}
.alice-carousel__prev-btn {
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;
}
.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item {
  width: 40px !important;
  height: 50px;
  transform: translate(-0.5rem, 0.2rem);
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  display: none;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 25px !important;
  height: 2px !important;
  cursor: pointer;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
// @media only screen and (max-width: 1024px) {
//   .alice-carousel__dots {
//     display: none;
//   }
// }
.alice-carousel__next-btn-wrapper,
.alice-carousel__prev-btn-wrapper {
  background-image: url(../../../../../../images/icons/arrows-right.svg) !important;
  background-size: 100% 100% !important;
  width: 1.1rem;
  opacity: 0.8;
  transition: 0.2s ease all;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.alice-carousel__prev-btn-wrapper {
  transform: rotate(180deg) translateX(2rem);
  position: absolute;
  top: 30%;
}
.alice-carousel__next-btn-wrapper {
  transform: translateX(2rem);
  position: absolute;
  top: 30%;
}

.alice-carousel__next-btn-item span:after,
.alice-carousel__prev-btn-item span:after {
  content: "" !important;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 10px !important;
}
@media only screen and (max-width: 1092px) {
  .alice-carousel__prev-btn-wrapper {
    transform: rotate(180deg) translateX(-0.5rem);
  }
  .alice-carousel__next-btn-wrapper {
    transform: translateX(-1.3rem);
  }
}
@media only screen and (max-width: 950px) {
  .alice-carousel__prev-btn-wrapper {
    transform: rotate(180deg) translateX(0.5rem);
  }
  .alice-carousel__next-btn-wrapper {
    transform: translateX(0.5rem);
  }
}

@media only screen and (max-width: 767px) {
  .alice-carousel__prev-btn-wrapper {
    transform: rotate(180deg) translateX(1.3rem);
  }
  .alice-carousel__next-btn-wrapper {
    transform: translateX(1.3rem);
  }
}
@media only screen and (max-width: 634px) {
  .alice-carousel__prev-btn-wrapper {
    transform: rotate(180deg) translateX(-1rem);
  }
  .alice-carousel__next-btn-wrapper {
    transform: translateX(-1rem);
  }
}
@media only screen and (max-width: 525px) {
  .alice-carousel__prev-btn-wrapper {
    transform: rotate(180deg) translateX(0rem);
  }
  .alice-carousel__next-btn-wrapper {
    transform: translateX(0rem);
  }
}
@media only screen and (max-width: 475px) {
  .alice-carousel__prev-btn-wrapper {
    top: 20%;
    transform: rotate(180deg) translateX(1rem);
  }
  .alice-carousel__next-btn-wrapper {
    top: 20%;
    transform: translateX(1rem);
  }
}
