.header {
  transition: 0.2s ease all;
  position: fixed;
  width: 100%;
  top: 0%;
  background: transparent;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  .logo {
    width: 80px;
    opacity: 0.9;
    margin: 0;
    height: 100%;
    margin-left: 0.55rem;
  }
  .newsletter {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  // TOOLTIP
  div,
  .tooltip {
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 16px !important;
    font-family: LemonMilk;
  }
  .tooltip {
    margin-top: 0.2rem !important;
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .tooltip-newsletter,
  .tooltip-newsletter-big {
    text-transform: uppercase !important;
    background: #29191f !important;
    letter-spacing: 1px !important;
    color: rgb(255, 208, 70) !important;
    font-family: LemonMilk;
  }
  .tooltip-newsletter-big {
    border-radius: 3px;
    position: relative !important;
    padding: 8px 21px;
    text-align: center;
    width: 40%;
    transform: translateY(0.5rem);
  }
  .btn {
    background-color: transparent !important;
    border: none;
    outline-style: none !important;
    padding: 0 !important;
  }
}
svg.newsletter,
a.newsletter {
  transition: 0.2s ease all;
  path {
    fill: rgb(255, 208, 70) !important;
  }
}

@media only screen and (max-width: 992px) {
  .mail-chimp.active {
    margin-top: 3rem !important;
  }
}
@media only screen and (max-width: 767px) {
  .mail-chimp.active {
    margin-top: 1rem !important;
  }
}
@media only screen and (max-width: 375px) {
  .mail-chimp.active {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 768px) and (orientation: landscape) {
  .header {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }
}

@media only screen and (max-width: 576px) {
  .logo {
    width: 70px !important;
  }
}
