.home {
  .recordings {
    position: relative;
    min-height: 100vh;
    background: inherit;
    padding-top: 3rem;
    h3 {
      color: rgba($color: #ffffff, $alpha: 1);
    }
    .recordings-wrap {
      background-color: rgba(0, 0, 0, 0.3);
      min-height: 600px;

      .list-grid {
        padding-top: 1.5rem;
        padding-bottom: 0.7rem;
        font-size: 20px;
        svg {
          cursor: pointer;
          width: 45px;
          path {
            fill: rgba(255, 255, 255, 0.6) !important;
            transition: 0.2s ease all;
          }
        }
        svg.active {
          path {
            fill: rgba(255, 255, 255, 1) !important;
          }
        }
      }
      .card-wrapper {
        padding: 2rem 4rem;
        padding-bottom: 0;
      }
    }
    .card-wrapper.grid {
      transition: 0.2s ease all;
      display: none;
    }
    .card-wrapper.grid.show {
      display: flex;
      align-items: center;
      min-height: 500px;
    }
    .card-wrapper.list {
      transition: 0.2s ease all;
      display: none;
    }
    .card-wrapper.list.show {
      display: block;
    }
  }
}

.stream,
.card-text {
  svg {
    width: 40px !important;
    path {
      fill: rgba(255, 255, 255, 0.8) !important;
      transition: 0.2s ease all;
    }
    &:hover {
      path {
        fill: rgba(255, 255, 255, 1) !important;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .card-wrapper {
    padding: 1rem 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media only screen and (max-width: 992px) {
  .card-wrapper {
    padding: 1rem 1rem !important;
    padding-bottom: 2rem !important;
  }
}
