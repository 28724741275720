.card {
  .vinyl-link {
    color: inherit;
    padding-right: 0.5rem;
    svg {
      max-width: 30px;
      transition: 0.2s ease all;
      path {
        transition: 0.2s ease all;
      }
    }
    &:hover {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }
  .vinyl-img {
    text-align: center;
    position: absolute;
    right: 5%;
    padding: 0.5rem 0;
    z-index: 1;
    transition: 0.4s ease all;
    img {
      width: 100%;
    }
  }
  .top-img {
    transition: 0.4s ease all;
    z-index: 2;
  }
  .merch-img {
    z-index: 2;
  }
  &:hover {
    .vinyl-img {
      transform: translateX(1rem);
    }
    .top-img {
      transform: translateX(-6.5rem);
    }
  }
}
@media only screen and (max-width: 1024px) {
  .vinyl-img {
    transform: translateX(0.8rem);
  }
  .top-img {
    transform: translateX(-2rem);
  }
}
@media only screen and (max-width: 375px) {
  .vinyl-img {
    transform: translateX(0.5rem);
  }
  .top-img {
    transform: translateX(-1.5rem);
  }
}
