#main {
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .newsletter-campaign {
    cursor: pointer;
    .campaign-hover-text.active {
      background-color: rgba($color: #000000, $alpha: 0.8) !important;
    }
    .campaign-hover-text.active {
      top: 32%;
    }
    img.active {
      filter: blur(1.5px);
    }
    .campaign-hover-text {
      text-align: center;
      width: 100%;
      position: absolute;
      top: -100%;
      z-index: 2;
      transition: 0.2s;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 0.5rem 0;
      hr {
        background-color: rgba(255, 255, 255, 0.5) !important;
        width: 50%;
        text-align: center;
        margin: 0.4rem auto;
      }
      a {
        color: rgba(255, 255, 255, 0.9) !important;
        transition: 0.2s ease all;
        &:hover {
          color: rgba(255, 255, 255, 0.7) !important;
        }
      }
      h3 {
        font-size: 22px;
        font-family: LemonMilk;
      }
    }
  }
  .newsletter-campaign-text {
    color: rgba(236, 213, 143, 0.95);
    opacity: 0.8;
  }
  // .sign-up-button {
  //   margin-top: 1rem;
  //   background-color: #29191f !important;
  //   padding: 8px 21px !important;
  //   border: transparent !important;
  //   border-radius: 3px !important;
  //   color: rgba(255, 208, 70, 1) !important;
  //   z-index: 1 !important;
  //   letter-spacing: 0.9px;
  //   opacity: 0.8;
  //   transition: 0.2s ease all !important;
  //   &:hover {
  //     opacity: 1;
  //   }
  // }

  @media only screen and (max-width: 767px) {
    .newsletter-campaign {
      padding-bottom: 6rem;
      .campaign-hover-text.active {
        font-size: 14px;
        top: 25% !important;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .newsletter-campaign {
      .campaign-hover-text.active {
        top: 27% !important;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .newsletter-campaign {
      max-width: 250px !important;
      .campaign-hover-text.active {
        top: 15% !important;
      }
    }
  }
}
