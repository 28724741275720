.header {
  .header-svg.active {
    svg {
      fill: rgb(255, 255, 255) !important;
    }
  }
  svg {
    width: 30px;
    margin-right: 0.35rem;
    margin-left: 0.3rem;
    height: 40%;
  }
  a {
    path {
      transition: 0.5s ease all;
      fill: rgba(255, 255, 255, 0.6) !important;
    }
    &:hover {
      path {
        fill: rgb(255, 255, 255) !important;
      }
      svg.newsletter {
        path {
          fill: rgba(255, 208, 70, 1) !important;
        }
      }
    }
  }
  a.active {
    path {
      fill: rgba(255, 255, 255, 1) !important;
    }
  }
  .place-bottom {
    padding-top: 4px !important;
    margin-top: 0px !important;
  }
  .place-bottom::before,
  .place-bottom::after {
    display: none !important;
  }
}
.popper-hide {
  visibility: hidden;
  transition: 0.1s ease all;
  pointer-events: none;
}
.val-text {
  background-color: rgba($color: #000000, $alpha: 0.8);
}
