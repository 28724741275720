.footer {
  background: inherit;
  letter-spacing: 0.9px;
  .footer-wrap {
    background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
    min-height: 100vh;
    position: relative;
    svg {
      width: 35px;
      path {
        fill: rgba(255, 255, 255, 0.8) !important;
        transition: 0.2s ease all;
      }
      &:hover {
        path {
          fill: rgba(255, 255, 255, 1) !important;
        }
      }
    }
    .copy-right {
      position: absolute;
      width: 100%;
      bottom: 0;
      color: rgba($color: #ffffff, $alpha: 0.2);
      font-size: 12px;
      a {
        color: rgba($color: #ffffff, $alpha: 0.3);
        transition: 0.2s ease all;
        &:hover {
          color: rgba($color: #ffffff, $alpha: 0.5);
        }
      }
    }
    .email {
      color: rgba(255, 255, 255, 0.8) !important;
      font-size: 18px;
      a {
        color: inherit;
        text-decoration: none;
        p {
          transition: 0.2s ease all;
        }
        p {
          &:hover {
            color: rgba($color: #ffffff, $alpha: 1);
          }
        }
      }
    }
  }
}
